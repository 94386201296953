export default {
  loading: 'Loading',
  homepage: 'Homepage',
  search: 'Search',
  default: 'Default',
  save: 'Save',
  noRecord: 'No Records',
  add: 'Add',
  date: 'Date',
  delete: 'Delete',
  navMenu: {
    login: 'Login',
    register: 'Register',
    myAccount: 'My Account',
    logout: 'Log out',
    quantity: 'Quantity',
    sku: 'Model',
    option: 'Option',
    total: 'Total',
    viewCart: 'View Cart',
    checkout: 'Checkout',
    noItemInCart: 'No item in cart',
    aboutUs: 'About Us',
  },
  footer: {
    aboutUs: 'About Us',
    usefulLinks: 'Useful Links',
    disclaimer: 'Terms & conditions',
    privacyPolicy: 'Delivery & Returns Policy',
    tnc: 'Terms and Conditions',
    guide: 'Shopping Guide',
    subscribeUs: 'Subscribe Us',
    Facebook: 'Facebook',
    Youtube: 'Youtube',
    Instagram: 'Instagram',
  },
  home: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    buyNow: 'Buy Now',
    latestProducts: 'New Arrivals',
    latestProductsSubTitle: 'Provide you the latest products each month',
    new: 'New',
    selectOptions: 'Select Options',
    addToCart: 'Add to Cart',
    addedToCart: 'Added to Cart',
    outOfStock: 'Coming Soon',
    view: 'View',
    addedToWishlist: 'Added to Wishlist',
    addToWishlist: 'Add to Wishlist',
    blogTitle: 'How\'s our KOL say?',
    writtenBy: 'Written by',
    featureTitle1: '2 FREE SAMPLES',
    featureSubTitle1: 'With Every Order',
    featureTitle2: 'FREE NEXT DAY DELIVERY',
    featureSubTitle2: 'Order by 12 pm. HK Only.',
    featureTitle3: 'FREE GIFT WRAP',
    featureSubTitle3: 'Select At Checkout',
    bannerTitle1: '',
    bannerTitle2: '',
    viewMoreProducts: 'View more products',
  },
  productList: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    allProducts: 'All Products',
    priceLowToHigh: 'Price - Low to High',
    priceHighToLow: 'Price - High to Low',
    updateTimeNewToOld: 'Update Time - New to Old',
    updateTimeOldToNew: 'Update Time - Old to New',
    categories: 'Categories',
    noCategories: 'All Categories',
    buyNow: 'Buy Now',
    new: 'New',
    selectOptions: 'Select Options',
    addToCart: 'Add to Cart',
    addedToCart: 'Added to Cart',
    outOfStock: 'Coming Soon',
    view: 'View',
    addedToWishlist: 'Added to Wishlist',
    addToWishlist: 'Add to Wishlist',
    addedToCompare: 'Added to Compare',
    addToCompare: 'Add to Compare',
  },
  productDetails: {
    metaTitle: 'Luxe Young | Product Detail',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    productDetails: 'Product Details',
    sku: 'Model',
    option: 'Option',
    buyNow: 'Buy Now',
    addToCart: 'Add to Cart',
    outOfStock: 'Coming Soon',
    additionalInfo: 'Additional information',
    alsoBuy: 'Packages',
    quantity: 'Quantity',
    packageTotal: 'Package Total',
    relatedProducts: 'Related Product',
  },
  loginRegister: {
    metaTitle: 'Luxe Young | Login / Register',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    loginRegister: 'Login｜Register',
    login: 'Login',
    register: 'Register',
    email: 'Email',
    password: 'Password',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot Password',
    firstName: 'First Name',
    lastName: 'Last Name',
    phone: 'Phone',
    loginPanelTitle: 'Already a member?',
    registerPanelTitle: 'Join now!',
    registerPanelSubTitle: 'Register to become a member. Start tuned of our latest news!',
    registerModalTitle: 'Member Register',
    resetPasswordMsg: 'We will send an email to your email address for reset password if your record is found in our system.',
    validateAccountMsg: 'Email validation success.',
  },
  myAccount: {
    metaTitle: 'Luxe Young | My Account',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    myAccount: 'My Account',
    personalInfo: 'Personal Information',
    deliveryAddress: 'Delivery Address',
    orderRecord: 'Order Records',
    personalInfoTitle: 'Your Personal Information',
    firstName: 'First Name',
    lastName: 'Last Name',
    phone: 'Phone',
    email: 'Email',
    changePassword: 'Change Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    addNewAddress: 'Add New Delivery Address',
    addressLine1: 'Street Address',
    addressLine2: 'Street Address 2',
    addressLine3: 'City',
    stateProvince: 'State / Province',
    zipCode: 'Zip Code',
    countryRegion: 'Country / Region',
    order: 'Order',
    orderId: 'Order ID',
    orderStatus: 'Order Status',
    paymentStatus: 'Payment Status',
    deliveryStatus: 'Delivery Status',
    total: 'Total',
    details: 'Details',
    receiverName: 'Receiver Name',
    contactPhone: 'Contact Number',
    image: 'Image',
    productName: 'Product Name',
    unitPrice: 'Unit Price',
    quantity: 'Quantity',
    subTotal: 'Sub Total',
    sku: 'Model',
    option: 'Option',
  },
  cart: {
    metaTitle: 'Luxe Young | Cart',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    cart: 'Cart',
    yourCart: 'Your Cart',
    image: 'Image',
    productName: 'Product Name',
    unitPrice: 'unit Price',
    quantity: 'Quantity',
    subTotal: 'Sub Total',
    sku: 'Model',
    option: 'Option',
    continueShopping: 'Continue Shopping',
    clearCart: 'Clear Cart',
    useCoupon: 'Use Coupon Code',
    inputCouponCode: 'Input Coupon Code',
    applyCouponCode: 'Apply Coupon Code',
    cartTotal: 'Cart Total',
    totalDiscount: 'Total Discount',
    total: 'Grand Total',
    continueCheckout: 'Checkout',
    noItemInCart: 'No Item in your cart',
    buyNow: 'Buy Now',
  },
  checkout: {
    metaTitle: 'Luxe Young | Checkout',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    checkout: 'Checkout',
    billingDetails: 'Billing Details',
    firstName: 'First Name',
    lastName: 'Last Name',
    phone: 'Phone',
    email: 'Email',
    companyName: 'Company Name',
    addressLine1: 'Street Address',
    addressLine2: 'Street Address 2',
    addressLine3: 'City',
    stateProvince: 'State / Province',
    zipCode: 'Zip Code',
    countryRegion: 'Country / Region',
    additionalInfo: 'Additional Information',
    otherRemarks: 'Other Remarks',
    yourOrder: 'Your Order',
    product: 'Product',
    total: 'Total',
    delivery: 'Delivery',
    discount: 'Discount',
    totalDiscount: 'Total Discount',
    freeShipping: 'Free Shipping',
    submitOrder: 'Submit Order',
    noItemInCart: 'No item in your cart',
    buyNow: 'Buy Now',
  },
  paymentNotification: {
    metaTitle: 'Luxe Young | Payment Result',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    paymentNotification: 'Payment Result',
    backToHome: 'Back to Homepage',
    orderSuccessTitle: 'Order Success',
    orderSuccessMsg: 'Thanks for purchasing! We have received your order and please check your email for further information.',
    orderCancelledTitle: 'Order Cancelled',
    orderCancelledMsg: 'Your order may be cancelled. Please contact our customer services for further information.',
    orderPendingTitle: 'Order Pending',
    orderPendingMsg: 'Thanks for purchasing! We have received your order and please check your email for further information.',
  },
  aboutUs: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    aboutUs: 'About Us',
  },
  blog: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    relatedProducts: 'Related Products',
    blogPost: 'Blog Post'
  },
  wishlist: {
    metaTitle: 'PinNBuy',
    metaName: 'PinNBuy',
    metaContent: 'PinNBuy',
    wishlist: 'Wishlist',
    image: 'Image',
    productName: 'Product Name',
    unitPrice: 'Unit Price',
    selectOptions: 'Select Options',
    addToCart: 'Add to Cart',
    addedToCart: 'Added to Cart',
    outOfStock: 'Coming Soon',
    continueShopping: 'Continue Shopping',
  },
  compare: {
    metaTitle: 'PinNBuy',
    metaName: 'PinNBuy',
    metaContent: 'PinNBuy',
    compare: 'Compare',
    product: 'Product',
    unitPrice: 'unit Price',
    description: 'Description',
    sku: 'Model',
    option: 'Option',
    additionalInfo: 'Additional information',
    view: 'View',
    continueShopping: 'Continue Shopping',
  },
  resetPassword: {
    metaTitle: 'PinNBuy',
    metaName: 'PinNBuy',
    metaContent: 'PinNBuy',
    resetPassword: 'Reset Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
  },
  alerts: {
    accountNotFound: 'Account not found',
    wrongEmail: 'Please provide correct email',
    wrongConfirmPassword: 'New and confirm password are different',
    wrongPasswordFormat: 'Please fill in correct password.\n' +
      'Password consists of at least 8 characters, which must include number and letters (at least one capital letter)',
    wrongPhoneFormat: 'Please provide correct mobile',
    fillNecessaryInfo: 'Please fill in all necessary information',
    updateAccountInfoSuccess: 'Information has been updated',
    updatePasswordSuccess: 'Password has been updated',
    referencePriceRemarks: 'Reference price',
    loginFailed: 'Incorrect email or password.',
  },
};
