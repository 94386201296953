export default {
  loading: 'Cargando',
  homepage: 'Página principal',
  search: 'Buscar',
  default: 'Defecto',
  save: 'guardar',
  noRecord: 'No hay registros',
  add: 'Añadir',
  date: 'Fecha',
  delete: 'Eliminar',
  navMenu: {
    login: 'Registrarse',
    register: 'Registrarse',
    myAccount: 'Mi cuenta',
    logout: 'Finalizar la sesión',
    quantity: 'Cantidad',
    sku: 'Modelo',
    option: 'Opción',
    total: 'Total',
    viewCart: 'Ver carrito de compras',
    checkout: 'Revisa',
    noItemInCart: 'Ningún artículo en su carrito',
    aboutUs: 'Sobre nosotros',
  },
  footer: {
    aboutUs: 'Sobre nosotros',
    usefulLinks: 'Enlaces útiles',
    disclaimer: 'Descargo de responsabilidad',
    privacyPolicy: 'Políticas de privacidad',
    tnc: 'Terms and Conditions',
    guide: 'Shopping Guide',
    subscribeUs: 'Suscríbete',
    Facebook: 'Facebook',
    Youtube: 'Youtube',
    Instagram: 'Instagram',
  },
  home: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    buyNow: 'Compra ahora',
    latestProducts: 'Nuevos llegadas',
    latestProductsSubTitle: 'Proporcionarle los últimos productos cada mes',
    new: 'Nueva',
    selectOptions: 'Seleccione opciones',
    addToCart: 'Añadir al carrito',
    addedToCart: 'Añadido al carrito',
    outOfStock: 'Agotado',
    view: 'Ver',
    addedToWishlist: 'Añadido a la lista de deseos',
    addToWishlist: 'Añadir a la lista de deseos',
    blogTitle: 'How\'s our KOL say?',
    writtenBy: 'Written by',
    featureTitle1: '2 FREE SAMPLES',
    featureSubTitle1: 'With Every Order',
    featureTitle2: 'FREE NEXT DAY DELIVERY',
    featureSubTitle2: 'Order by 12 pm. HK Only.',
    featureTitle3: 'FREE GIFT WRAP',
    featureSubTitle3: 'Select At Checkout',
    bannerTitle1: '',
    bannerTitle2: '',
    viewMoreProducts: 'Ver más productos',
  },
  productList: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    allProducts: 'Todos los productos',
    priceLowToHigh: 'Precio - Bajo a Alto',
    priceHighToLow: 'Precio - Alto a Bajo',
    updateTimeNewToOld: 'Tiempo de actualización - nuevo a viejo',
    updateTimeOldToNew: 'Tiempo de actualización - viejo a nuevo',
    categories: 'Categorías',
    noCategories: 'Todas las categorias',
    buyNow: 'Compra ahora',
    new: 'Nuevo',
    selectOptions: 'Seleccione opciones',
    addToCart: 'Añadir al carrito',
    addedToCart: 'Añadido al carrito',
    outOfStock: 'Agotado',
    view: 'Ver',
    addedToWishlist: 'Añadido a la lista de deseos',
    addToWishlist: 'Añadir a la lista de deseos',
    addedToCompare: 'Agregado para comparar',
    addToCompare: 'Añadir a comparar',
  },
  productDetails: {
    metaTitle: 'Luxe Young | Product Detail',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    productDetails: 'Detalles de producto',
    sku: 'Modelo',
    option: 'Opción',
    buyNow: 'Compra ahora',
    addToCart: 'Añadir al carrito',
    outOfStock: 'Agotado',
    additionalInfo: 'Información Adicional',
    alsoBuy: 'Oferta de paquete',
    quantity: 'Cantidad',
    packageTotal: 'Total del paquete',
    relatedProducts: 'También te puede interesar',
  },
  loginRegister: {
    metaTitle: 'Luxe Young | Registrarse / Registrarse',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    loginRegister: 'Registrarse｜Registrarse',
    login: 'Registrarse',
    register: 'Registrarse',
    email: 'Correo electrónico',
    password: 'Contraseña',
    rememberMe: 'Recuérdame',
    forgotPassword: 'Contraseña olvidada',
    firstName: 'Nombre de pila',
    lastName: 'Apellido',
    phone: 'Móvil',
    loginPanelTitle: '¿Ya eres usuario?',
    registerPanelTitle: '¡Únete a nosotros hoy!',
    registerPanelSubTitle: 'Registrarse para ser miembro. ¡Empiece a estar atento a nuestras últimas novedades!',
    registerModalTitle: 'Registro de miembros',
    resetPasswordMsg: 'We will send an email to your email address for reset password if your record is found in our system.',
    validateAccountMsg: 'Email validation success.',
  },
  myAccount: {
    metaTitle: 'Luxe Young | My Account',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    myAccount: 'My Account',
    personalInfo: 'Información de la cuenta',
    deliveryAddress: 'Dirección de entrega',
    orderRecord: 'Registros de pedidos',
    personalInfoTitle: 'Tu información personal',
    firstName: 'Nombre de pila',
    lastName: 'Apellido',
    phone: 'Teléfono',
    email: 'Correo electrónico',
    changePassword: 'Cambia la contraseña',
    newPassword: 'Nueva contraseña',
    confirmPassword: 'Confirmar contraseña',
    addNewAddress: 'Nueva dirección de envío',
    addressLine1: 'Dirección 1',
    addressLine2: 'Dirección 2',
    addressLine3: 'Ciudad',
    stateProvince: 'Estato/Provincia',
    zipCode: 'Código postal',
    countryRegion: 'País / Región',
    order: 'Orden',
    orderId: 'Orden ID',
    orderStatus: 'Estado del pedido',
    paymentStatus: 'Estado de pago',
    deliveryStatus: 'Estado de entrega',
    total: 'Total',
    details: 'Detalles',
    receiverName: 'Nombre del destinatario',
    contactPhone: 'Número de contacto',
    image: 'Imagen',
    productName: 'Nombre del producto',
    unitPrice: 'Precio unitario',
    quantity: 'Cantidad',
    subTotal: 'Total parcial',
    sku: 'Modelo',
    option: 'Opción',
  },
  cart: {
    metaTitle: 'Luxe Young | Cart',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    cart: 'Carro',
    yourCart: 'Su carrito',
    image: 'Imagen',
    productName: 'nombre del producto',
    unitPrice: 'Precio unitario',
    quantity: 'Cantidad',
    subTotal: 'Total parcial',
    sku: 'Modelo',
    option: 'Opción',
    continueShopping: 'Seguir comprando',
    clearCart: 'Vaciar carrito',
    useCoupon: 'Usar código de cupón',
    inputCouponCode: 'Seleccione / inserte su código de cupón aquí',
    applyCouponCode: 'Aplicar código de cupón',
    cartTotal: 'Total del carrito',
    totalDiscount: 'Descuento total',
    total: 'gran total',
    continueCheckout: 'pasar por la caja',
    noItemInCart: 'Ningún artículo en su carrito',
    buyNow: 'Compra ahora',
  },
  checkout: {
    metaTitle: 'Luxe Young | Checkout',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    checkout: 'Revisa',
    billingDetails: 'Billing Details',
    firstName: 'Nombre de pila',
    lastName: 'Apellido',
    phone: 'Teléfono',
    email: 'Correo electrónico',
    companyName: 'Nombre',
    addressLine1: 'Dirección 1',
    addressLine2: 'Dirección 2',
    addressLine3: 'Ciudad',
    stateProvince: 'Estato/Provincia',
    zipCode: 'Código postale',
    countryRegion: 'País / Región',
    additionalInfo: 'Información Adicional',
    otherRemarks: 'Otras observaciones',
    yourOrder: 'Su pedido',
    product: 'Producto',
    total: 'Total',
    delivery: 'Entrega',
    discount: 'Descuento',
    totalDiscount: 'Descuento total',
    freeShipping: 'Envío gratis',
    submitOrder: 'realizar pedido',
    noItemInCart: 'Ningún artículo en su carrito',
    buyNow: 'Compra ahora',
  },
  paymentNotification: {
    metaTitle: 'Luxe Young | Payment Result',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    paymentNotification: 'Notificacion de pago',
    backToHome: 'De vuelta a casa',
    orderSuccessTitle: 'Pedido exitoso',
    orderSuccessMsg: '¡Gracias por comprar! Hemos recibido su pedido y consulte su correo electrónico para obtener más información.',
    orderCancelledTitle: 'Orden cancelada',
    orderCancelledMsg: 'Su orden puede ser cancelada. Póngase en contacto con nuestro servicio de atención al cliente para obtener más información.',
    orderPendingTitle: 'Orden pendiente',
    orderPendingMsg: '¡Gracias por comprar! Hemos recibido su pedido y consulte su correo electrónico para obtener más información.',
  },
  aboutUs: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    aboutUs: 'Sobre nosotros',
  },
  blog: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    relatedProducts: 'Producto relacionado',
    blogPost: 'Entrada en el blog'
  },
  wishlist: {
    metaTitle: 'PinNBuy',
    metaName: 'PinNBuy',
    metaContent: 'PinNBuy',
    wishlist: 'Lista de deseos',
    image: 'Imagen',
    productName: 'nombre del producto',
    unitPrice: 'Precio unitario',
    selectOptions: 'Seleccione opciones',
    addToCart: 'Añadir al carrito',
    addedToCart: 'Añadido al carrito',
    outOfStock: 'Agotado',
    continueShopping: 'Seguir comprando',
  },
  compare: {
    metaTitle: 'PinNBuy',
    metaName: 'PinNBuy',
    metaContent: 'PinNBuy',
    compare: 'Compare',
    product: 'Product',
    unitPrice: 'unit Price',
    description: 'Description',
    sku: 'Model',
    option: 'Option',
    additionalInfo: 'Additional information',
    view: 'View',
    continueShopping: 'Continue Shopping',
  },
  resetPassword: {
    metaTitle: 'PinNBuy',
    metaName: 'PinNBuy',
    metaContent: 'PinNBuy',
    resetPassword: 'Reset Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
  },
  alerts: {
    accountNotFound: 'Account not found',
    wrongEmail: 'Please provide correct email',
    wrongConfirmPassword: 'New and confirm password are different',
    wrongPasswordFormat: 'Please fill in correct password.\n' +
      'Password consists of at least 8 characters, which must include number and letters (at least one capital letter)',
    wrongPhoneFormat: 'Please provide correct mobile',
    fillNecessaryInfo: 'Please fill in all necessary information',
    updateAccountInfoSuccess: 'Information has been updated',
    updatePasswordSuccess: 'Password has been updated',
    referencePriceRemarks: 'Reference price',
    loginFailed: 'Incorrect email or password.',
  },
};
