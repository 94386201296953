export default {
  loading: '로딩 중',
  homepage: '홈페이지',
  search: '검색',
  default: '기본',
  save: '저장',
  noRecord: '기록 없음',
  add: '더하다',
  date: '데이트',
  delete: '지우다',
  navMenu: {
    login: '로그인',
    register: '레지스터',
    myAccount: '내 계정',
    logout: '로그 아웃',
    quantity: '수량',
    sku: '모델',
    option: '선택권',
    total: '합계',
    viewCart: '장바구니보기',
    checkout: '정착',
    noItemInCart: '장바구니에 항목이 없습니다',
    aboutUs: '우리에 대해',
  },
  footer: {
    aboutUs: '우리에 대해',
    usefulLinks: '유용한 링크',
    disclaimer: '부인 성명',
    privacyPolicy: '개인 정보 보호 정책',
    tnc: 'Terms and Conditions',
    guide: 'Shopping Guide',
    subscribeUs: '우리를 구독하십시오',
    Facebook: 'Facebook',
    Youtube: 'Youtube',
    Instagram: 'Instagram',
  },
  home: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    buyNow: '지금 구입',
    latestProducts: '새로운 도착',
    latestProductsSubTitle: '매달 최신 제품 제공',
    new: '새로운',
    selectOptions: '옵션 선택',
    addToCart: '장바구니에 담기',
    addedToCart: '장바구니에 추가',
    outOfStock: '품절',
    view: '전망',
    addedToWishlist: '위시리스트에 추가',
    addToWishlist: '위시리스트에 추가',
    blogTitle: 'How\'s our KOL say?',
    writtenBy: 'Written by',
    featureTitle1: '2 FREE SAMPLES',
    featureSubTitle1: 'With Every Order',
    featureTitle2: 'FREE NEXT DAY DELIVERY',
    featureSubTitle2: 'Order by 12 pm. HK Only.',
    featureTitle3: 'FREE GIFT WRAP',
    featureSubTitle3: 'Select At Checkout',
    bannerTitle1: '',
    bannerTitle2: '',
    viewMoreProducts: '더 많은 제품보기',
  },
  productList: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    allProducts: '전체 상품',
    priceLowToHigh: '가격-낮은 가격순',
    priceHighToLow: '가격-높은 가격순',
    updateTimeNewToOld: '업데이트 시간-새 것부터 이전 것',
    updateTimeOldToNew: '업데이트 시간-이전에서 새',
    categories: '카테고리',
    noCategories: '모든 카테고리',
    buyNow: '지금 구입',
    new: '새로운',
    selectOptions: '옵션 선택',
    addToCart: '장바구니에 담기',
    addedToCart: '장바구니에 추가',
    outOfStock: '품절',
    view: '전망',
    addedToWishlist: '위시리스트에 추가',
    addToWishlist: '위시리스트에 추가',
    addedToCompare: '비교에 추가',
    addToCompare: '비교에 추가',
  },
  productDetails: {
    metaTitle: 'Luxe Young | Product Detail',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    productDetails: '제품 세부 정보',
    sku: '모델',
    option: '선택권',
    buyNow: '지금 구입',
    addToCart: '장바구니에 담기',
    outOfStock: '품절',
    additionalInfo: '추가 정보',
    alsoBuy: '번들 제공',
    quantity: '수량',
    packageTotal: '패키지 합계',
    relatedProducts: '당신은 또한 좋아할 수 있습니다',
  },
  loginRegister: {
    metaTitle: 'Luxe Young | 로그인 / 레지스터',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    loginRegister: '로그인｜레지스터',
    login: '로그인',
    register: '레지스터',
    email: '이메일 주소',
    password: '비밀번호',
    rememberMe: '날 기억해',
    forgotPassword: '비밀번호 분실',
    firstName: '이름',
    lastName: '성',
    phone: '휴대폰 번호',
    loginPanelTitle: '이미 회원이십니까?',
    registerPanelTitle: '오늘 가입하세요!',
    registerPanelSubTitle: '회원 가입을하세요. 최신 뉴스를 확인하세요!',
    registerModalTitle: '레지스터',
    resetPasswordMsg: 'We will send an email to your email address for reset password if your record is found in our system.',
    validateAccountMsg: 'Email validation success.',
  },
  myAccount: {
    metaTitle: 'Luxe Young | My Account',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    myAccount: '내 계정',
    personalInfo: '계정 정보',
    deliveryAddress: '배송 주소',
    orderRecord: '주문 기록',
    personalInfoTitle: '귀하의 개인 정보',
    firstName: '이름',
    lastName: '성',
    phone: '휴대폰 번호',
    email: '이메일 주소',
    changePassword: '비밀번호 변경',
    newPassword: '새 비밀번호',
    confirmPassword: '비밀번호 확인',
    addNewAddress: '새로운 배송 주소',
    addressLine1: '주소 1',
    addressLine2: '주소 2',
    addressLine3: '시티',
    stateProvince: '주 /도',
    zipCode: '우편 번호',
    countryRegion: '국가 / 지역',
    order: '주문',
    orderId: '주문 아이디',
    orderStatus: '주문 상태',
    paymentStatus: '지불 상태',
    deliveryStatus: '배송 상황',
    total: '합계',
    details: '세부',
    receiverName: '수취인 성명',
    contactPhone: '연락처',
    image: '영상',
    productName: '상품명',
    unitPrice: '단가',
    quantity: '수량',
    subTotal: '소계',
    sku: '모델',
    option: '선택권',
  },
  cart: {
    metaTitle: 'Luxe Young | Cart',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    cart: '카트',
    yourCart: '카트',
    image: '영상',
    productName: '상품명',
    unitPrice: '단가',
    quantity: '수량',
    subTotal: '소계',
    sku: 'Model',
    option: 'Option',
    continueShopping: '쇼핑을 계속',
    clearCart: '카트 지우기',
    useCoupon: '쿠폰 코드 사용',
    inputCouponCode: '여기에서 쿠폰 코드를 선택 / 입력하십시오',
    applyCouponCode: '쿠폰 코드 적용',
    cartTotal: '장바구니 합계',
    totalDiscount: '총 할인',
    total: '총계',
    continueCheckout: '결제 진행',
    noItemInCart: '장바구니에 항목이 없습니다',
    buyNow: '지금 구입',
  },
  checkout: {
    metaTitle: 'Luxe Young | Checkout',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    checkout: '정착',
    billingDetails: 'Billing Details',
    firstName: '이름',
    lastName: '성',
    phone: '휴대폰 번호',
    email: '이메일 주소',
    companyName: '이름',
    addressLine1: '주소 1',
    addressLine2: '주소 2',
    addressLine3: '시티',
    stateProvince: '주 /도',
    zipCode: '우편 번호',
    countryRegion: '국가 / 지역',
    additionalInfo: '추가 정보',
    otherRemarks: '기타 비고',
    yourOrder: '주문',
    product: '생성물',
    total: '합계',
    delivery: '배달',
    discount: '할인',
    totalDiscount: '총 할인',
    freeShipping: '무료 배송',
    submitOrder: '주문하기',
    noItemInCart: '장바구니에 항목이 없습니다',
    buyNow: '지금 구입',
  },
  paymentNotification: {
    metaTitle: 'Luxe Young | Payment Result',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    paymentNotification: '지불 결과',
    backToHome: '홈 페이지로 돌아 가기',
    orderSuccessTitle: '주문 성공',
    orderSuccessMsg: '구매 해주셔서 감사합니다! 주문이 접수되었으며 자세한 내용은 이메일을 확인하십시오.',
    orderCancelledTitle: '주문 취소',
    orderCancelledMsg: '주문이 취소 될 수 있습니다. 자세한 내용은 고객 서비스에 문의하십시오.',
    orderPendingTitle: '주문 보류',
    orderPendingMsg: '구매 해주셔서 감사합니다! 주문이 접수되었으며 자세한 내용은 이메일을 확인하십시오.',
  },
  aboutUs: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    aboutUs: '우리에 대해',
  },
  blog: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    relatedProducts: '관련 상품',
    blogPost: '블로그 포스트'
  },
  wishlist: {
    metaTitle: 'PinNBuy',
    metaName: 'PinNBuy',
    metaContent: 'PinNBuy',
    wishlist: '위시리스트',
    image: '영상',
    productName: '상품명',
    unitPrice: '단가',
    selectOptions: '옵션 선택',
    addToCart: '장바구니에 담기',
    addedToCart: '장바구니에 추가',
    outOfStock: '품절',
    continueShopping: '쇼핑을 계속',
  },
  compare: {
    metaTitle: 'PinNBuy',
    metaName: 'PinNBuy',
    metaContent: 'PinNBuy',
    compare: 'Compare',
    product: 'Product',
    unitPrice: 'unit Price',
    description: 'Description',
    sku: 'Model',
    option: 'Option',
    additionalInfo: 'Additional information',
    view: 'View',
    continueShopping: 'Continue Shopping',
  },
  resetPassword: {
    metaTitle: 'PinNBuy',
    metaName: 'PinNBuy',
    metaContent: 'PinNBuy',
    resetPassword: 'Reset Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
  },
  alerts: {
    accountNotFound: 'Account not found',
    wrongEmail: 'Please provide correct email',
    wrongConfirmPassword: 'New and confirm password are different',
    wrongPasswordFormat: 'Please fill in correct password.\n' +
      'Password consists of at least 8 characters, which must include number and letters (at least one capital letter)',
    wrongPhoneFormat: 'Please provide correct mobile',
    fillNecessaryInfo: 'Please fill in all necessary information',
    updateAccountInfoSuccess: 'Information has been updated',
    updatePasswordSuccess: 'Password has been updated',
    referencePriceRemarks: 'Reference price',
    loginFailed: 'Incorrect email or password.',
  },
};
