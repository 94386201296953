export default {
  loading: '載入中',
  homepage: '主頁',
  search: '搜尋',
  default: '預設',
  save: '儲存',
  noRecord: '沒有紀錄',
  add: '新增',
  date: '日期',
  delete: '刪除',
  navMenu: {
    login: '登入',
    register: '註冊',
    myAccount: '我的帳戶',
    logout: '登出',
    quantity: '數量',
    sku: '款式',
    option: '選項',
    total: '總計',
    viewCart: '檢視購物車',
    checkout: '結算',
    noItemInCart: '購物車內沒有貨物',
    aboutUs: '關於我們',
  },
  footer: {
    aboutUs: '關於我們',
    usefulLinks: '有用連結',
    disclaimer: '條款及細則',
    privacyPolicy: '送貨及退貨政策',
    tnc: '使用條款',
    guide: '購物指南',
    subscribeUs: '關注我們',
    Facebook: 'Facebook',
    Youtube: 'Youtube',
    Instagram: 'Instagram',
  },
  home: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    buyNow: '立即選購',
    latestProducts: '最新產品',
    latestProductsSubTitle: '送上每月最新產品',
    new: '新',
    selectOptions: '選擇款式',
    addToCart: '新增至購物車',
    addedToCart: '已加到購物車',
    outOfStock: '即將推出',
    view: '檢視',
    addedToWishlist: '已加到最愛',
    addToWishlist: '加到最愛',
    blogTitle: 'How\'s our KOL say?',
    writtenBy: '編',
    featureTitle1: '2 FREE SAMPLES',
    featureSubTitle1: 'With Every Order',
    featureTitle2: 'FREE NEXT DAY DELIVERY',
    featureSubTitle2: 'Order by 12 pm. HK Only.',
    featureTitle3: 'FREE GIFT WRAP',
    featureSubTitle3: 'Select At Checkout',
    bannerTitle1: '',
    bannerTitle2: '',
    viewMoreProducts: '更多產品',
  },
  productList: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    allProducts: '所有產品',
    priceLowToHigh: '價錢 - 低至高',
    priceHighToLow: '價錢 - 高至低',
    updateTimeNewToOld: '更新時間 - 新到舊',
    updateTimeOldToNew: '更新時間 - 舊到新',
    categories: '產品種類',
    noCategories: '所有',
    buyNow: '立即選購',
    new: '新',
    selectOptions: '選擇款式',
    addToCart: '新增至購物車',
    addedToCart: '已加到購物車',
    outOfStock: '即將推出',
    view: '檢視',
    addedToWishlist: '已加到最愛',
    addToWishlist: '加到最愛',
    addedToCompare: '已加到比較',
    addToCompare: '加到比較',
  },
  productDetails: {
    metaTitle: 'Luxe Young | 產品詳情',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    productDetails: '產品詳情',
    sku: '款式',
    option: '選項',
    buyNow: '立即選購',
    addToCart: '加入購物車',
    outOfStock: '即將推出',
    additionalInfo: '更多資料',
    alsoBuy: '通常也會買',
    quantity: '件數',
    packageTotal: '套裝總價',
    relatedProducts: '相關產品',
  },
  loginRegister: {
    metaTitle: 'Luxe Young | 登入 / 註冊',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    loginRegister: '登入｜註冊',
    login: '登入',
    register: '註冊',
    email: '電郵地址',
    password: '密碼',
    rememberMe: '記住我',
    forgotPassword: '忘記密碼',
    firstName: '名字',
    lastName: '姓氏',
    phone: '電話',
    loginPanelTitle: '已經是會員?',
    registerPanelTitle: '立即加入!',
    registerPanelSubTitle: '登記成為會員以獲取最新品牌資訊',
    registerModalTitle: '註冊會員',
    resetPasswordMsg: '如您所輸入的電郵地址符合我們的系統紀錄，我們將會發送重置密碼之電郵到您的電郵地址。',
    validateAccountMsg: '電郵地址認證成功',
  },
  myAccount: {
    metaTitle: 'Luxe Young | 我的帳戶',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    myAccount: '我的帳戶',
    personalInfo: '個人資料',
    deliveryAddress: '送貨地址',
    orderRecord: '訂單記錄',
    personalInfoTitle: '你的個人詳情',
    firstName: '名字',
    lastName: '姓氏',
    phone: '電話',
    email: '電郵地址',
    changePassword: '更改密碼',
    newPassword: '新密碼',
    confirmPassword: '確認新密碼',
    addNewAddress: '新增送貨地址',
    addressLine1: '街道地址',
    addressLine2: '街道地址 2',
    addressLine3: '城市',
    stateProvince: '州 / 省',
    zipCode: '郵遞編號',
    countryRegion: '國家 / 地區',
    order: '訂單',
    orderId: '訂單編號',
    orderStatus: '訂單狀態',
    paymentStatus: '付款狀態',
    deliveryStatus: '送貨狀態',
    total: '總計',
    details: '詳情',
    receiverName: '收件人姓名',
    contactPhone: '聯絡電話',
    image: '圖片',
    productName: '產品名字',
    unitPrice: '單價',
    quantity: '數量',
    subTotal: '小計',
    sku: '款式',
    option: '選項',
  },
  cart: {
    metaTitle: 'Luxe Young | 購物車',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    cart: '購物車',
    yourCart: '你的購物車',
    image: '圖片',
    productName: '產品名字',
    unitPrice: '單價',
    quantity: '數量',
    subTotal: '小計',
    sku: '款式',
    option: '選項',
    continueShopping: '繼續購物',
    clearCart: '清空購物車',
    useCoupon: '使用優惠碼',
    inputCouponCode: '輸入優惠碼',
    applyCouponCode: '應用優惠碼',
    cartTotal: '購物車總計',
    totalDiscount: '總折扣',
    total: '總計',
    continueCheckout: '繼續結算',
    noItemInCart: '購物車內沒有貨物',
    buyNow: '立即選購',
  },
  checkout: {
    metaTitle: 'Luxe Young | 結算',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    checkout: '結算',
    billingDetails: '帳單詳情',
    firstName: '名字',
    lastName: '姓氏',
    phone: '電話',
    email: '電郵地址',
    companyName: '公司名稱',
    addressLine1: '街道地址',
    addressLine2: '街道地址 2',
    addressLine3: '城市',
    stateProvince: '州 / 省',
    zipCode: '郵遞編號',
    countryRegion: '國家 / 地區',
    additionalInfo: '額外資訊',
    otherRemarks: '其他備註',
    yourOrder: '您的訂單',
    product: '產品',
    total: '總計',
    delivery: '送貨',
    freeShipping: '免費送貨',
    submitOrder: '提交訂單',
    discount: '折扣',
    totalDiscount: '總折扣',
    noItemInCart: '購物車內沒有貨物',
    buyNow: '立即選購',
  },
  paymentNotification: {
    metaTitle: 'Luxe Young | 付款結果',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    paymentNotification: '付款結果',
    backToHome: '返回主頁',
    orderSuccessTitle: '成功下單',
    orderSuccessMsg: '感謝您的購買！ 我們已收到您的訂單，請檢查您的電子郵件以獲取更多信息。',
    orderCancelledTitle: '訂單取消',
    orderCancelledMsg: '您的訂單已被取消， 請聯繫客戶服務以獲取更多信息。',
    orderPendingTitle: '待處理訂單',
    orderPendingMsg: '感謝您的購買！ 我們已收到您的訂單，請檢查您的電子郵件以獲取更多信息。',
  },
  aboutUs: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    aboutUs: '關於我們',
  },
  blog: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    relatedProducts: '相關產品',
    blogPost: 'Blog Post'
  },
  wishlist: {
    metaTitle: 'PinNBuy',
    metaName: 'PinNBuy',
    metaContent: 'PinNBuy',
    wishlist: '收藏',
    image: '圖片',
    productName: '產品名字',
    unitPrice: '單價',
    selectOptions: '選擇款式',
    addToCart: '新增至購物車',
    addedToCart: '已加到購物車',
    outOfStock: '即將推出',
    continueShopping: '繼續購物',
  },
  compare: {
    metaTitle: 'PinNBuy',
    metaName: 'PinNBuy',
    metaContent: 'PinNBuy',
    compare: '比較',
    product: '產品',
    unitPrice: '單價',
    description: '產品描述',
    sku: '款式',
    option: '選項',
    additionalInfo: '更多資料',
    view: '檢視',
    continueShopping: '繼續購物',
  },
  resetPassword: {
    metaTitle: 'PinNBuy',
    metaName: 'PinNBuy',
    metaContent: 'PinNBuy',
    resetPassword: '重置密碼',
    newPassword: '新密碼',
    confirmPassword: '確認新密碼',
  },
  alerts: {
    accountNotFound: '帳戶不存在',
    wrongEmail: '請提供正確的電郵地址',
    wrongConfirmPassword: '新密碼與確認密碼不符',
    wrongPasswordFormat: '請提供正確的密碼，密碼需至少包含8個字元，以數字及英文字母組成 (包括1個大階字母)',
    wrongPhoneFormat: '請提供正確的手提號碼',
    fillNecessaryInfo: '請填寫全部資料',
    updateAccountInfoSuccess: '資料已被更改',
    updatePasswordSuccess: '密碼已被更改',
    referencePriceRemarks: '參考價錢',
    loginFailed: '電郵地址或密碼不正確',
  },
};
