import { SET_CURRENCY } from "../actions/currencyActions";

const initState = {
  currencySymbol: "£",
  currencyName: "gbp",
  currencyRate: 1,
  defaultCurrencyName: "gbp",
};

const currencyReducer = (state = initState, action) => {
  if (action.type === SET_CURRENCY) {
    const currencyName = action.payload.currencyName;

    let updatedCurrency = {
      currencySymbol: "$",
      currencyRate: action.payload.currencyRate,
      currencyName
    };
    if (action.payload.defaultCurrencyName) updatedCurrency.defaultCurrencyName = action.payload.defaultCurrencyName;

    switch (currencyName.toLowerCase()){
      case 'eur':
        updatedCurrency.currencySymbol = "€";
        break;
      case 'gbp':
        updatedCurrency.currencySymbol = "£";
        break;
      case 'krw':
        updatedCurrency.currencySymbol = "₩";
        break;
      case 'jpy':
        updatedCurrency.currencySymbol = "¥";
        break;
    }
    return {
      ...state,
      ...updatedCurrency,
    };
  }

  return state;
};

export default currencyReducer;
