export default {
  loading: '読み込み中',
  homepage: 'ホームページ',
  search: '探す',
  default: 'デフォルト',
  save: '保存する',
  noRecord: '記録なし',
  add: '追加',
  date: '日付',
  delete: '削除',
  navMenu: {
    login: 'サインイン',
    register: '登録',
    myAccount: 'マイアカウント',
    logout: 'ログアウト',
    quantity: '量',
    sku: 'モデル',
    option: 'オプション',
    total: '合計',
    viewCart: 'かごの中身を見る',
    checkout: 'チェックアウト',
    noItemInCart: 'カートにアイテムがありません',
    aboutUs: '私たちに関しては',
  },
  footer: {
    aboutUs: '私たちに関しては',
    usefulLinks: '便利なリンク',
    disclaimer: '免責事項',
    privacyPolicy: 'プライバシーポリシー',
    tnc: 'Terms and Conditions',
    guide: 'Shopping Guide',
    subscribeUs: '購読する',
    Facebook: 'Facebook',
    Youtube: 'Youtube',
    Instagram: 'Instagram',
  },
  home: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    buyNow: '今買う',
    latestProducts: '新着',
    latestProductsSubTitle: '毎月最新の製品を提供します',
    new: '新着',
    selectOptions: 'オプションを選択',
    addToCart: 'カートに追加',
    addedToCart: 'カートに追加',
    outOfStock: '在庫切れ',
    view: '見る',
    addedToWishlist: 'ウィッシュリストに追加',
    addToWishlist: 'ほしい物リストに追加する',
    blogTitle: 'How\'s our KOL say?',
    writtenBy: 'Written by',
    featureTitle1: '2 FREE SAMPLES',
    featureSubTitle1: 'With Every Order',
    featureTitle2: 'FREE NEXT DAY DELIVERY',
    featureSubTitle2: 'Order by 12 pm. HK Only.',
    featureTitle3: 'FREE GIFT WRAP',
    featureSubTitle3: 'Select At Checkout',
    bannerTitle1: '',
    bannerTitle2: '',
    viewMoreProducts: '他の製品を見る',
  },
  productList: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    allProducts: 'すべての製品',
    priceLowToHigh: '価格-低から高',
    priceHighToLow: '価格-高から低',
    updateTimeNewToOld: '更新時間-新旧',
    updateTimeOldToNew: '更新時間-古いものから新しいものへ',
    categories: 'カテゴリ',
    noCategories: 'すべてのカテゴリ',
    buyNow: '今買う',
    new: '新着',
    selectOptions: 'オプションを選択',
    addToCart: 'カートに追加',
    addedToCart: 'カートに追加',
    outOfStock: '在庫切れ',
    view: '見る',
    addedToWishlist: 'ウィッシュリストに追加',
    addToWishlist: 'ほしい物リストに追加する',
    addedToCompare: '比較に追加',
    addToCompare: '比較リストに追加',
  },
  productDetails: {
    metaTitle: 'Luxe Young | Product Detail',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    productDetails: '製品詳細',
    sku: 'モデル',
    option: 'オプション',
    buyNow: '今買う',
    addToCart: 'カートに追加',
    outOfStock: '在庫切れ',
    additionalInfo: '追加情報',
    alsoBuy: 'バンドルオファー',
    quantity: '量',
    packageTotal: 'パッケージ合計',
    relatedProducts: 'あなたもこれが好きかもしれません',
  },
  loginRegister: {
    metaTitle: 'Luxe Young | サインイン / 登録',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    loginRegister: 'サインイン｜登録',
    login: 'サインイン',
    register: '登録',
    email: '電子メールアドレス',
    password: 'パスワード',
    rememberMe: '私を覚えてますか',
    forgotPassword: 'パスワードを忘れる',
    firstName: '名前',
    lastName: '氏',
    phone: '携帯電話番号',
    loginPanelTitle: 'すでにメンバーですか？',
    registerPanelTitle: '今日ご参加ください！',
    registerPanelSubTitle: 'メンバーになるために登録してください。 私たちの最新ニュースをお楽しみに！',
    registerModalTitle: '登録',
    resetPasswordMsg: 'We will send an email to your email address for reset password if your record is found in our system.',
    validateAccountMsg: 'Email validation success.',
  },
  myAccount: {
    metaTitle: 'Luxe Young | My Account',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    myAccount: 'マイアカウント',
    personalInfo: '口座情報',
    deliveryAddress: '発送先住所',
    orderRecord: '注文記録',
    personalInfoTitle: 'あなたの個人情報',
    firstName: '名前',
    lastName: '氏',
    phone: '携帯電話番号',
    email: '電子メールアドレス',
    changePassword: 'パスワードを変更する',
    newPassword: '新しいパスワード',
    confirmPassword: 'パスワードを認証する',
    addNewAddress: '新しい出荷先住所',
    addressLine1: '住所1',
    addressLine2: '住所2',
    addressLine3: '市',
    stateProvince: '州/県',
    zipCode: '郵便番号',
    countryRegion: '国/地域',
    order: '注文',
    orderId: '注文ID',
    orderStatus: '注文の状況',
    paymentStatus: '支払い状況',
    deliveryStatus: '配送状況',
    total: '合計',
    details: '詳細',
    receiverName: '受信名',
    contactPhone: '連絡先番号',
    image: '画像',
    productName: '商品名',
    unitPrice: '単価',
    quantity: '量',
    subTotal: '小計',
    sku: 'モデル',
    option: 'オプション',
  },
  cart: {
    metaTitle: 'Luxe Young | Cart',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    cart: 'カート',
    yourCart: 'あなたのカート',
    image: '画像',
    productName: '商品名',
    unitPrice: '単価',
    quantity: '量',
    subTotal: '小計',
    sku: 'モデル',
    option: 'オプション',
    continueShopping: 'ショッピングを続ける',
    clearCart: 'クリアカート',
    useCoupon: 'クーポンコードを使用する',
    inputCouponCode: 'ここでクーポンコードを選択/入力してください',
    applyCouponCode: 'クーポンコードを適用する',
    cartTotal: 'カート合計',
    totalDiscount: '合計割引',
    total: '総計',
    continueCheckout: 'チェックアウトに進む',
    noItemInCart: 'カートにアイテムがありません',
    buyNow: '今買う',
  },
  checkout: {
    metaTitle: 'Luxe Young | Checkout',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    checkout: 'チェックアウト',
    billingDetails: 'Billing Details',
    firstName: '名前',
    lastName: '氏',
    phone: '携帯電話番号',
    email: '電子メールアドレス',
    companyName: '名前',
    addressLine1: '住所1',
    addressLine2: '住所2',
    addressLine3: '市',
    stateProvince: '州/県',
    zipCode: '郵便番号',
    countryRegion: '国/地域',
    additionalInfo: '追加情報',
    otherRemarks: 'その他の備考',
    yourOrder: 'ご注文',
    product: '製品',
    total: '合計',
    delivery: '配達',
    discount: 'ディスカウント',
    totalDiscount: '合計割引',
    freeShipping: '送料無料',
    submitOrder: '注文する',
    noItemInCart: 'カートにアイテムがありません',
    buyNow: '今買う',
  },
  paymentNotification: {
    metaTitle: 'Luxe Young | Payment Result',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    paymentNotification: '支払い通知',
    backToHome: '家に帰る',
    orderSuccessTitle: '注文の成功',
    orderSuccessMsg: 'ご購入いただきありがとうございます！ ご注文を承りました。詳細についてはメールをご確認ください。',
    orderCancelledTitle: '注文はキャンセルされました',
    orderCancelledMsg: 'ご注文はキャンセルされる場合があります。 詳細については、カスタマーサービスにお問い合わせください。',
    orderPendingTitle: '注文保留中',
    orderPendingMsg: 'ご購入いただきありがとうございます！ ご注文を承りました。詳細についてはメールをご確認ください。',
  },
  aboutUs: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    aboutUs: '私たちに関しては',
  },
  blog: {
    metaTitle: 'Luxe Young',
    metaName: 'Luxe Young',
    metaContent: 'Luxe Young',
    relatedProducts: '関連製品',
    blogPost: 'ブログ投稿'
  },
  wishlist: {
    metaTitle: 'PinNBuy',
    metaName: 'PinNBuy',
    metaContent: 'PinNBuy',
    wishlist: 'ウィッシュリスト',
    image: '画像',
    productName: '商品名',
    unitPrice: '単価',
    selectOptions: 'オプションを選択',
    addToCart: 'カートに追加',
    addedToCart: 'カートに追加',
    outOfStock: '在庫切れ',
    continueShopping: 'ショッピングを続ける',
  },
  compare: {
    metaTitle: 'PinNBuy',
    metaName: 'PinNBuy',
    metaContent: 'PinNBuy',
    compare: 'Compare',
    product: 'Product',
    unitPrice: 'unit Price',
    description: 'Description',
    sku: 'Model',
    option: 'Option',
    additionalInfo: 'Additional information',
    view: 'View',
    continueShopping: 'Continue Shopping',
  },
  resetPassword: {
    metaTitle: 'PinNBuy',
    metaName: 'PinNBuy',
    metaContent: 'PinNBuy',
    resetPassword: 'Reset Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
  },
  alerts: {
    accountNotFound: 'Account not found',
    wrongEmail: 'Please provide correct email',
    wrongConfirmPassword: 'New and confirm password are different',
    wrongPasswordFormat: 'Please fill in correct password.\n' +
      'Password consists of at least 8 characters, which must include number and letters (at least one capital letter)',
    wrongPhoneFormat: 'Please provide correct mobile',
    fillNecessaryInfo: 'Please fill in all necessary information',
    updateAccountInfoSuccess: 'Information has been updated',
    updatePasswordSuccess: 'Password has been updated',
    referencePriceRemarks: 'Reference price',
    loginFailed: 'Incorrect email or password.',
  },
};
